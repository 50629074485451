import React, { useState } from "react";
import {
  Button,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  UncontrolledTooltip,
} from "reactstrap";

const SADADPayment = ({ SADADToggle, SADADNumber, SADADError }) => {
  // console.log("SADADError", SADADError);
  // console.log("SADADNumber", SADADNumber);
  //************* form control ******/
  const [modal, setmodal] = useState(true);
  const toggle = () => {
    SADADToggle();
  };
  const onClose = () => {
    SADADToggle();
  };

  const [tooltipText, setTooptipText] = useState("SADAD Payment Number");
  const SADADNumberHandler = async () => {
    setTooptipText("Copied");

    try {
      await navigator.clipboard.writeText(SADADNumber);
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }

    setTimeout(() => {
      setTooptipText("SADAD Payment Number");
    }, 2000);
  };

  return (
    <Modal isOpen={modal} toggle={toggle} className="modal-md">
      <ModalHeader>
        SADAD Payment
        <button
          type="button"
          className="btn-close position-absolute end-0 top-0 m-3"
          onClick={onClose}
        />
      </ModalHeader>
      <div className="modal-content">
        <ModalBody className="px-4 pt-5">
          {SADADNumber && !SADADError ? (
            <>
              <div
                style={{
                  border: "1px solid gray",
                  borderRadius: "5px",
                  overflow: "hidden",
                }}
                className="d-flex justify-content-between mb-3"
              >
                <div className="d-flex justify-content-center align-items-center p-0">
                  <p className="px-3 py-2 m-0">{SADADNumber}</p>
                </div>
                <div
                  style={{
                    backgroundColor: "lightgray",
                    borderLeft: "1px solid gray",
                    cursor: "pointer",
                  }}
                  className="px-3 d-flex justify-content-center align-items-center"
                  id="SADADCopied"
                  onClick={SADADNumberHandler}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 448 512"
                    style={{ marginRight: "10px" }}
                  >
                    <path d="M208 0H332.1c12.7 0 24.9 5.1 33.9 14.1l67.9 67.9c9 9 14.1 21.2 14.1 33.9V336c0 26.5-21.5 48-48 48H208c-26.5 0-48-21.5-48-48V48c0-26.5 21.5-48 48-48zM48 128h80v64H64V448H256V416h64v48c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V176c0-26.5 21.5-48 48-48z" />
                  </svg>{" "}
                  <span style={{ fontWeight: "500" }}>Copy</span>
                </div>
                <UncontrolledTooltip placement="left" target="SADADCopied">
                  {tooltipText}
                </UncontrolledTooltip>
              </div>

              <div className="mx-2">
                <Label>
                  <span className="fw-bold">Biller Name: </span>ROSOM
                </Label>
              </div>
              <div className="mx-2">
                <Label>
                  <span className="fw-bold">Biller Code: </span>901
                </Label>
              </div>
            </>
          ) : !SADADNumber && SADADError ? (
            <div className="text-center">
              <h4 className="text-danger">There is an error!</h4>
              <p>{SADADError}</p>
            </div>
          ) : null}

          <FormGroup className="d-flex justify-content-end mt-3">
            <button className="btn btn-primary" onClick={onClose}>
              Close
            </button>
          </FormGroup>
        </ModalBody>
      </div>
    </Modal>
  );
};

export default SADADPayment;
