import React from "react";
import { useSearchParams } from "react-router-dom";
import "./paymentCallback.css";

const PaymentCallback = () => {
  const [searchParams] = useSearchParams();

  const error = searchParams.get("error");
  const TN = searchParams.get("TN");

  // console.log("error", error);
  // console.log("TN", TN);

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        // backgroundImage: "linear-gradient(to top left, #4F2B90, #088e5a)",
        backgroundColor: "#f4f4f4",
      }}
      className="d-flex flex-column align-items-center justify-content-center"
    >
      {error === "0" && TN && TN !== "Response signature is invalid." ? (
        <div className="cardPay p-3">
          <i
            style={{ color: "green", fontSize: "1.5rem" }}
            class="completeIcon bi bi-bookmark-check-fill "
          ></i>
          <div className="text-center mb-2">
            <i
              style={{ color: "green", fontSize: "2.5rem" }}
              class="checkIcon bi bi-check-circle-fill"
            ></i>
            <h5>Successful</h5>
          </div>
          <hr />
          <div className="p-3" style={{ minWidth: "220px" }}>
            <p>Transaction No: {TN}</p>
          </div>
          {/* <hr />
          <div className="text-center">
            <button
              className="btn btn-success"
              onClick={() => window.location.replace("https://CPVarabia.com")}
            >
              Done
            </button>
          </div> */}
        </div>
      ) : (
        <div className="cardPayX p-3">
          <i
            style={{
              color: "#dc3545",
              fontSize: "1.5rem",
              position: "absolute",
              top: "-10px",
            }}
            className="completeIcon bi bi-bookmark-x-fill"
          ></i>
          <div className="text-center mb-2">
            <i
              style={{ color: "#e31111", fontSize: "2.5rem" }}
              className="XIcon bi bi-x-circle-fill"
            ></i>

            <h5>Failed</h5>
          </div>
          <hr />
          <div className="p-3" style={{ minWidth: "220px" }}>
            <p className="text-center">Try again !</p>
          </div>
          {/* <hr />
          <div className="text-center">
            <button
              className="btn btn-danger"
              onClick={() => window.location.replace("https://CPVarabia.com")}
            >
              Done
            </button>
          </div> */}
        </div>
      )}

      <div className="pt-2">
        <h5 className="text-muted">CPV Global Arabia LTD</h5>
      </div>
      {/* <Card
        className="my-2 mx-auto text-center mb-5"
        style={{
          width: "18rem",
          border: "none",
          boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.30)",
        }}
      >
        <CardHeader
          style={{
            backgroundImage: "linear-gradient(to top, #54565B, #3A3C40)",
          }}
          className="text-light "
        >
          <h5 className="py-3 m-0">CPV Global Arabia LTD</h5>
        </CardHeader>
        <CardBody className="py-5">
          <CardTitle tag="h5">
            {error === "0" && TN && TN !== "Response signature is invalid."
              ? "Successful"
              : "Failed"}
          </CardTitle>
          <CardText>
            {error === "0" && TN && TN !== "Response signature is invalid."
              ? `Transaction No: ${TN}`
              : "Try again later!"}
          </CardText>
        </CardBody>
        <CardFooter
          style={{ cursor: "pointer" }}
          className="bg-success text-light"
          onClick={() => window.location.replace("https://CPVarabia.com")}
        >
          <h5 className="py-2 m-0">Done</h5>
        </CardFooter>
      </Card> */}
    </div>
  );
};

export default PaymentCallback;
